export enum TrackEvent {
    ViewedVenue = 'ViewedVenue',
    LoggedIn = 'LoggedIn',
    LoggedOut = 'LoggedOut',
    ViewedItem = 'ViewedItem',
    AddedItem = 'AddedItem',
    RemovedItem = 'RemovedItem',
    StartedCheckout = 'StartedCheckout',
    CompletedPurchase = 'CompletedPurchase',
    ReservationRequested = 'ReservationRequested',
    //
    Click_RequestTable = 'Click_RequestTable',
}