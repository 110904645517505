import { logger } from '@lib/logger'
import { DateTime } from 'luxon'
import { InputOption } from 'types/ui'
import { DayDetail } from 'types/ui/calendar'

export const CALENDAR_MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

export const MONTH_OPTIONS: InputOption[] = CALENDAR_MONTHS.map((month, index) => ({
  name: month,
  id: index.toString()
}))

export const CALENDAR_WEEKDAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
]

export const WEEKDAY_OPTIONS: InputOption[] = CALENDAR_WEEKDAYS.map((weekday, index) => ({
  name: weekday,
  id: index.toString()
}))

export function getBeginningOfDate(dt?: Date | number | string | null) {
  const date = !dt ? new Date() : new Date(dt)

  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0)
}

export function utcToISO(
  utc?: number | string | undefined | null,
  options: { startOfDay: boolean } = { startOfDay: false }
) {
  if (!utc) return ''
  const { startOfDay } = options

  const dt = new Date(utc)

  const utcDate = new Date(
    dt.getUTCFullYear(),
    dt.getUTCMonth(),
    dt.getUTCDate(),
    startOfDay ? 0 : dt.getUTCHours(),
    startOfDay ? 0 : dt.getUTCMinutes(),
    startOfDay ? 0 : dt.getUTCSeconds(),
    startOfDay ? 0 : dt.getUTCMilliseconds()
  )

  return utcDate.toISOString()
}

export function isoToUTC(
  iso: string | undefined | null,
  options: { startOfDay: boolean } = { startOfDay: false }
) {
  if (!iso) return
  const { startOfDay } = options

  const dt = new Date(iso)
  return Date.UTC(
    dt.getFullYear(),
    dt.getMonth(),
    dt.getDate(),
    startOfDay ? 0 : dt.getUTCHours(),
    startOfDay ? 0 : dt.getUTCMinutes(),
    startOfDay ? 0 : dt.getUTCSeconds()
  )
}

export function utcRange(
  start: number | string,
  { days = 0, months = 0, years = 0 }: { days?: number; months?: number; years?: number }
) {
  const beginningDate = new Date(start)
  const dateStart = Date.UTC(
    beginningDate.getFullYear(),
    beginningDate.getMonth(),
    beginningDate.getDate(),
    0,
    0,
    0
  )
  const dateEnd = Date.UTC(
    beginningDate.getFullYear() + years,
    beginningDate.getMonth() + months,
    beginningDate.getDate() + days,
    0,
    0,
    0
  )

  return [dateStart, dateEnd]
}

export function getOffsetUTC(date?: string | number | Date | null) {
  if (!date) return null
  try {
    const dt = new Date(date)
    return Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate(), 6).toString()
  } catch {
    return null
  }
}

export function getTimeLabel(value?: {
  hour?: number | undefined
  minute?: number | undefined
  meridiem?: 'AM' | 'PM' | undefined
}) {
  if (!value) return undefined
  const { hour, minute, meridiem } = value

  if (hour === undefined || minute === undefined || meridiem === undefined) return ''
  const minuteString = String(minute)
  return `${hour === 0 && meridiem === 'AM' ? 12 : hour}:${
    minuteString.length < 2 ? `0${minuteString}` : minuteString
  } ${meridiem}`
}

// TODO: try to replace luxon format with Intl.DateTimeFormat
export function withDateFormat(
  date: Date | number | string,
  formatString: string,
  timezone?: string
) {
  let dt =
    typeof date === 'number'
      ? DateTime.fromMillis(date).setZone(timezone)
      : typeof date === 'string'
        ? DateTime.fromISO(date).setZone(timezone)
        : DateTime.fromJSDate(date).setZone(timezone)
  return dt.toFormat(formatString)
}

export function getDaysInTheMonth(year: number, month: number) {
  return 40 - new Date(year, month, 40).getDate()
}

export function getCalendarDays(year: number, month: number) {
  try {
    const prevMonth = month === 0 ? 11 : month - 1
    const prevMonthYear = prevMonth === 11 ? year - 1 : year
    const prevMonthDayCount = getDaysInTheMonth(prevMonthYear, prevMonth)

    const jsFirstDate = new Date(year, month)
    const numberOfDays = getDaysInTheMonth(year, month)

    const firstDateWeekday = jsFirstDate.getDay()
    const calendarDayCells: DayDetail[] = Array(firstDateWeekday)

    let prevMonthWeekday = firstDateWeekday - 1
    let prevMonthDatesAdded = 0

    // fill in the cells for the previous month in the first row that will be disabled
    while (prevMonthWeekday >= 0) {
      const prevDateNumber = prevMonthDayCount - prevMonthDatesAdded
      const date = new Date(prevMonthYear, prevMonth, prevDateNumber, 0, 0, 0)
      calendarDayCells[prevMonthWeekday] = {
        date,
        rfc: rfcFormat(date.valueOf()),
        year: prevMonthYear,
        month: prevMonth,
        day: prevDateNumber,
        weekday: prevMonthWeekday,
        timestamp: date.valueOf()
      }
      ++prevMonthDatesAdded
      --prevMonthWeekday
    }

    // fill in the rest of the month days up until the saturday of the last row, extending into the next month if necessary
    let dayOfMonth = 1
    while (
      dayOfMonth <= numberOfDays ||
      (dayOfMonth > numberOfDays && calendarDayCells.at(-1)?.weekday !== 6)
    ) {
      const nextDate = new Date(year, month, dayOfMonth, 0, 0, 0)
      calendarDayCells.push({
        date: nextDate,
        rfc: rfcFormat(nextDate.valueOf()),
        year: nextDate.getFullYear(),
        month: nextDate.getMonth(),
        day: nextDate.getDate(),
        weekday: nextDate.getDay(),
        timestamp: nextDate.valueOf()
      })
      ++dayOfMonth
    }

    return calendarDayCells
  } catch (e) {
    logger.error('getCalendarDays(): Error', (e as Error).message || e)
    return []
  }
}

export function getDateFromNow({
  years = 0,
  months = 0,
  days = 0
}: { years?: number; months?: number; days?: number } = {}) {
  const nowDate = new Date()
  const nowYears = nowDate.getFullYear()
  const nowMonths = nowDate.getMonth()
  const nowDays = nowDate.getDate()

  const dateFromNow = new Date(nowYears + years, nowMonths + months, nowDays + days)
  return dateFromNow
}

export function getUTCFromNow({
  years = 0,
  months = 0,
  days = 0
}: { years?: number; months?: number; days?: number } = {}) {
  const nowDate = new Date()
  const nowYears = nowDate.getFullYear()
  const nowMonths = nowDate.getMonth()
  const nowDays = nowDate.getDate()

  return new Date(nowYears + years, nowMonths + months, nowDays + days).valueOf()
}

export function minuteNumberToString(val: number | null | undefined) {
  if (val === null || val === undefined) return ''
  return `${val < 10 ? '0' : ''}${val}`
}

export function addToDate(
  date: Date | string | number | null | undefined,
  units: {
    days?: number
    months?: number
    years?: number
    hours?: number
    minutes?: number
    seconds?: number
  }
) {
  if (!date) return

  const dt = new Date(date)
  const days = dt.getDate() + (units.days ?? 0)
  const months = dt.getMonth() + (units.months ?? 0)
  const years = dt.getFullYear() + (units.years ?? 0)
  const hours = dt.getHours() + (units.hours ?? 0)
  const minutes = dt.getMinutes() + (units.minutes ?? 0)
  const seconds = dt.getSeconds() + (units.seconds ?? 0)

  return new Date(years, months, days, hours, minutes, seconds)
}

export function rfcFormat(value?: number | string | null | undefined) {
  const val = new Date(value ?? Date.now())
  const yearString = String(val.getFullYear())
  const monthString = String(val.getMonth())
  const dayString = String(val.getDate())
  const pad = (value: string) => (value.length < 2 ? `0${value}` : value)
  return `${yearString}-${pad(monthString)}-${pad(dayString)}`
}

export function getYearFrom(value: string | number) {
  return new Date(value).getFullYear()
}

export function getMonthFrom(value: string | number) {
  return new Date(value).getMonth() + 1 // Month value is zero indexed on the Date object
}

export function getDayFrom(value: string | number) {
  return new Date(value).getDate()
}
