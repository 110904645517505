import { Venue } from 'types/model'
import { TrackEvent } from './types'

const gtmDataLayer = 'dataLayer'

const _window = typeof window !== 'undefined' ? window : undefined

//@ts-ignore
const dataLayer: any[] = !!_window
  ? (_window[gtmDataLayer as any] = _window[gtmDataLayer as any] ?? [])
  : []

class GoogleTagManagerClient {
  install(): void {
    dataLayer.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    })
  }

  startSession(): void {
    dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
  }

  async identifyUser({
    userId,
    traits,
  }: {
    userId: string
    traits?: object
  }) {
    dataLayer.push({
      userId,
    })
  }

  async track({
    event,
    eventData,
    venue,
  }: {
    event: TrackEvent
    eventData: {
      type?: string | null
      date?: string | null
      currency?: string
      itemId?: string
      itemPrice?: number
      itemName?: string
      itemCategory?: string
      itemQuantity?: number
      items?: {
        itemId?: string
        itemPrice?: number
        itemName?: string
        itemCategory?: string
        itemQuantity?: number
      }[]
      total?: number
      promoCode?: string
      tax?: number
      purchaseId?: string
    }
    venue: Venue
  }): Promise<void> {
    dataLayer.push({ ecommerce: null })

    console.log('TRACK GTM', {
      event,
      eventData,
      venue,
    })

    if (event === TrackEvent.ReservationRequested) {
      dataLayer.push({
        event: 'reservation_requested',
        ecommerce: {
          ...eventData,
          currency: eventData.currency,
          value: eventData.itemPrice,
          items: [mapEventToGAItemData({ event, eventData, venue })],
        },
      })
    } else if (event === TrackEvent.ViewedItem) {
      dataLayer.push({
        event: 'view_item',
        ecommerce: {
          ...eventData,
          currency: eventData.currency,
          value: eventData.itemPrice,
          items: [mapEventToGAItemData({ event, eventData, venue })],
        },
      })
    } else if (event === TrackEvent.AddedItem) {
      dataLayer.push({
        event: 'add_to_cart',
        ecommerce: {
          ...eventData,
          currency: eventData.currency,
          value: eventData.itemPrice,
          items: [mapEventToGAItemData({ event, eventData, venue })],
        },
      })
    } else if (event === TrackEvent.RemovedItem) {
      dataLayer.push({
        event: 'remove_from_cart',
        ecommerce: {
          ...eventData,
          currency: eventData.currency,
          value: eventData.itemPrice,
          items: [mapEventToGAItemData({ event, eventData, venue })],
        },
      })
    } else if (event === TrackEvent.StartedCheckout) {
      dataLayer.push({
        event: 'begin_checkout',
        ecommerce: {
          ...eventData,
          currency: eventData.currency,
          value: eventData.total,
          coupon: eventData.promoCode,
          tax: eventData.tax,
          items: (eventData.items ?? []).map((item: any) =>
            mapEventToGAItemData({ event, eventData: item, venue })
          ),
        },
      })
    } else if (event === TrackEvent.CompletedPurchase) {
      dataLayer.push({
        event: 'purchase',
        ecommerce: {
          ...eventData,
          currency: eventData.currency,
          transaction_id: eventData.purchaseId,
          value: eventData.total,
          coupon: eventData.promoCode,
          tax: eventData.tax,
          items: (eventData.items ?? []).map((item: any) =>
            mapEventToGAItemData({ event, eventData: item, venue })
          ),
        },
      })
    }
  }
}

function mapEventToGAItemData({
  event,
  eventData,
  venue,
}: {
  event: TrackEvent
  eventData: {
    currency?: string
    itemId?: string
    itemPrice?: number
    itemName?: string
    itemCategory?: string
    itemQuantity?: number
  }
  venue: Venue
}) {
  return {
    item_id: eventData.itemId,
    item_name: eventData.itemName,
    affiliation: venue.name,
    index: 0,
    item_category: eventData.itemCategory,
    location_id: venue.id,
    price: eventData.itemPrice,
    quantity: eventData.itemQuantity,
  }
}

const googleTagManagerClient = new GoogleTagManagerClient()

export default googleTagManagerClient