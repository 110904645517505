import { useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import { facebookLib } from '@lib/facebook'
import { Booking } from 'types/model/booking'
import { analytics } from '@lib/analytics'
import { PurchaseTrackingData } from 'types/lib/analytics'
import { TrackEvent } from '@utils/analytics/types'
import { utcToISO, withDateFormat } from '@utils/date-time'

export function usePageview() {
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = () => {
      facebookLib.trackPageview({ url: window.location.href })
    }
    router.events.on('routeChangeComplete', handleRouteChange)

    return () => router.events.off('routeChangeComplete', handleRouteChange)
  }, [router.events])

  useEffect(() => {
    facebookLib.trackPageview({ url: window.location.href })
  }, [])
}

export function usePurchaseConfirmation(booking: Booking) {
  const hasFired = useRef(false)

  useEffect(() => {
    if (hasFired.current || Date.now() - booking.modified > 30_000) return

    hasFired.current = true

    const purchaseData: PurchaseTrackingData = {
      name: booking.name,
      firstName: booking.primaryUser.firstName as string,
      lastName: booking.primaryUser.lastName as string,
      email: booking.primaryUser.email as string,
      userId: booking.primaryUser.id,
      bookingId: booking.id,
      venueId: booking.venue.id,
      eventId: booking.event,
      currency: booking.venue.currencyCode,
      value: (booking.total ?? 0).toString()
    }

    analytics.trackPurchase(purchaseData)

    analytics.trackEvent({
      event: TrackEvent.CompletedPurchase,
      venue: booking.venue,
      eventData: {
        date: utcToISO(booking.date),
        dateFormatted: withDateFormat(booking.date, 'MM/dd/yyyy', 'UTC'),
        arrivalTime: booking.arrivalTime ? withDateFormat(booking.arrivalTime, 'h:mm a', 'UTC') : undefined,
        dueOnArrival: booking.dueOnArrival,
        bookingId: booking.id,
        firstName: booking.primaryUser.firstName,
        lastName: booking.primaryUser.lastName,
        email: booking.primaryUser.email,
        userId: booking.primaryUser.id,
        phone: booking.primaryUser.phone,
        items: [{
          itemName: booking.location,
          itemId: booking.id,
          itemPrice: booking.price,
          itemCategory: booking.inventoryType,
          itemQuantity: 1,
        }],
        currency: booking.venue.currencyCode,
        total: booking.total,
        tax: booking.tax,
        purchaseId: booking.id,
        // promoCode: booking.promoCode,
      },
    })
  }, [booking])
}
