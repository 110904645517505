import {
  CheckoutTrackingData,
  PageviewTrackingData,
  PurchaseTrackingData
} from 'types/lib/analytics'

import { facebookLib } from './facebook'
import { Venue } from 'types/model'
import googleTagManagerClient from '@utils/analytics/gtm'
import { TrackEvent } from '../utils/analytics/types'

async function trackPageview({ url }: PageviewTrackingData) {
  await facebookLib.trackPageview({ url })
}

async function trackCheckout(vals: CheckoutTrackingData) {
  await facebookLib.trackCheckoutStart(vals)
}

async function trackPurchase(vals: PurchaseTrackingData) {
  await facebookLib.trackPurchase(vals)
}

async function trackEvent({
  event,
    eventData,
    venue,
}: {
  event: TrackEvent
  eventData: {
    type?: string | null
    date?: string | null
    dateFormatted?: string | null
    arrivalTime?: string | null
    guestCount?: number | null
    currency?: string
    dueOnArrival?: number | null
    depositAmount?: number | null
    arrivalStartTime?: string | null
    arrivalEndTime?: string | null
    bookingId?: string
    inventoryId?: string
    firstName?: string
    lastName?: string
    email?: string
    userId?: string
    phone?: string
    itemId?: string
    itemPrice?: number
    itemName?: string
    itemCategory?: string
    itemQuantity?: number
    items?: {
      itemId?: string
      itemPrice?: number
      itemName?: string
      itemCategory?: string
      itemQuantity?: number
    }[]
    total?: number
    promoCode?: string
    tax?: number
    purchaseId?: string
  }
  venue: Venue
}) {
  console.log('TRACK GTM EVENT', event, eventData, venue)

  return googleTagManagerClient.track({
    event, eventData, venue
  })
}

export const analytics = {
  trackPageview,
  trackCheckout,
  trackPurchase,
  trackEvent
}
